import React from "react";
import styles from "./ProductCardWithPrices.module.css";
import { Link } from "react-router-dom";
import carIcon from "../../media/iconsPricesPage/carIcon.svg";
import engineIcon from "../../media/iconsPricesPage/engineIcon.svg";
import transmissionIcon from "../../media/iconsPricesPage/transmissionIcon.svg";
import petrolIcon from "../../media/iconsPricesPage/petrolIcon.svg";

export default function ProductCardWithPrices({ car }) {
  return (
    <div className={styles.productCard}>
      <div className={styles.image}>

        {/* Отображение изображения автомобиля */}
        <img
          src={`https://okrentacar.md/admin/cars/uploads/${car.carImage}`}
          alt="test-car"
        />
      </div>

      <div className={styles.title}>

        {/* Название автомобиля */}
        <h4>{car.name}</h4>
      </div>

      <div className={styles.productInfoContainer}>

        {/* Отображение характеристик автомобиля */}
        <div>
          <img src={carIcon} alt="car-icon" />
          <p>
            An fabricație: <strong>{car.year}</strong>
          </p>
        </div>
        <div>
          <img src={engineIcon} alt="engine-icon" />
          <p>
            Capacitate: <strong>{car.capacityMotor}</strong>
          </p>
        </div>
        <div>
          <img src={transmissionIcon} alt="transmission-icon" />
          <p>
            Cutie: <strong>{car.transmission}</strong>
          </p>
        </div>
        <div>
          <img src={petrolIcon} alt="petrol-icon" />
          <p>
            Combustibil: <strong>{car.typeFuel}</strong>
          </p>
        </div>
      </div>

      <div className={styles.additionalDiv}>
        {" "}
        <div className={styles.title2}>

          {/* Название автомобиля */}
          <h4>{car.name}</h4>
        </div>
        <div className={styles.productInfoContainer2}>

          {/* Отображение характеристик автомобиля */}
          <div>
            <img src={carIcon} alt="car-icon" />
            <p>
              An fabricație: <strong>{car.year}</strong>
            </p>
          </div>
          <div>
            <img src={engineIcon} alt="engine-icon" />
            <p>
              Capacitate: <strong>{car.capacityMotor}</strong>
            </p>
          </div>
          <div>
            <img src={transmissionIcon} alt="transmission-icon" />
            <p>
              Cutie: <strong>{car.transmission}</strong>
            </p>
          </div>
          <div>
            <img src={petrolIcon} alt="petrol-icon" />
            <p>
              Combustibil: <strong>{car.typeFuel}</strong>
            </p>
          </div>
        </div>
      </div>

      <div className={styles.mobileVersion}>
        <div className={styles.title3}>

          {/* Название автомобиля */}
          <h4>{car.name}</h4>
        </div>

        <div className={styles.imageAndInfoContainer}>
          <div className={styles.image3}>

            {/* Отображение изображения автомобиля */}
            <img
              src={`https://okrentacar.md/admin/cars/uploads/${car.carImage}`}
              alt="test-car"
            />
          </div>

          <div className={styles.productInfoContainer3}>
            
            {/* Отображение характеристик автомобиля */}
            <div>
              <img src={carIcon} alt="car-icon" />
              <p>
                An fabricație: <strong>{car.year}</strong>
              </p>
            </div>
            <div>
              <img src={engineIcon} alt="engine-icon" />
              <p>
                Capacitate: <strong>{car.capacityMotor}</strong>
              </p>
            </div>
            <div>
              <img src={transmissionIcon} alt="transmission-icon" />
              <p>
                Cutie: <strong>{car.transmission}</strong>
              </p>
            </div>
            <div>
              <img src={petrolIcon} alt="petrol-icon" />
              <p>
                Combustibil: <strong>{car.typeFuel}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Цены за аренду автомобиля */}
      <div className={styles.prices}>
        <div>
          <div>
            1 - 2 zile<span> 55 €</span>
          </div>
          <div>
            3 - 7 zile<span> 45 €</span>
          </div>
          <div>
            8 - 15 zile
            <span> 55 €</span>
          </div>
          <div>
            16 - 21 zile
            <span> 35 €</span>
          </div>
          <div>
            22 - 50 zile
            <span> 30 €</span>
          </div>
          <div>
            50 + zile <span> 25 €</span>
          </div>
        </div>

        {/* Ссылка на страницу Single Product Page */}
        <Link to={`/product/${car.carCode}`}>
          <button className={styles.linkButton}>Închiriază</button>
        </Link>
      </div>
    </div>
  );
}
