import React, { useEffect, useState } from "react";
import styles from "./NavMenu.module.css";
import logo from "../../media/logo/logo.svg";
import adressIcon from "../../media/icons/adressIcon.svg";
import telephoneIcon from "../../media/icons/telephoneIcon.svg";
import vectorIcon from "../../media/icons/vectorIcon.svg";
import { Link, NavLink } from "react-router-dom";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

export default function NavMenu() {
 const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Состояние для хранения ширины окна

 // Эффект для обновления ширины окна при изменении размера
 useEffect(() => {
   const handleResize = () => {
     setWindowWidth(window.innerWidth); // Обновляем состояние ширины окна
   };

   window.addEventListener("resize", handleResize); // Добавляем слушатель события изменения размера окна

   // Очистка при размонтировании компонента
   return () => {
     window.removeEventListener("resize", handleResize); // Удаляем слушатель события
   };
 }, []);
 
  const handleLinkClick = () => {
    window.scrollTo(0, 0); // Прокрутить страницу вверх при клике на любую ссылку
  };

  return (
    <div className={styles.navMenu}>
      {/* Верхнее меню навигации */}
      <div className={styles.upperNavMenu}>
        <div className={styles.leftSide}>
          <Link to={"/"} onClick={handleLinkClick}>
            <img className={styles.logo} src={logo} alt="logo" />
          </Link>
        </div>

        {/* Условный рендеринг */}
        {windowWidth > 1024 ? (
          <div className={styles.rightSide}>
            <div>
              <img
                className={styles.icons}
                src={telephoneIcon}
                alt="telephone-icon"
              />
              <a href="tel:+37361094444" className={styles.contactLink}>
                <span>061 </span>094 444
              </a>
            </div>
            <div>
              <img
                className={styles.icons}
                src={adressIcon}
                alt="adress-icon"
              />
              <a
                href="https://www.google.com/maps/search/?api=1&query=Strada+Albișoara+5,+Chișinău,+Moldova"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.contactLink}
              >
                str. Albișoara 5
              </a>
            </div>
          </div>
        ) : (
          <BurgerMenu /> // Отображаем компонент BurgerMenu, если ширина окна меньше 1024px
        )}
      </div>{" "}

      {/* Нижнее меню навигации */}
      <div className={styles.lowerNavMenu}>
        <div className={styles.links}>
          <NavLink
            to="/"
            onClick={handleLinkClick}
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.activeLink}` : styles.link
            }
          >
            Pagina principală
          </NavLink>
          <NavLink
            to="/autos"
            onClick={handleLinkClick}
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.activeLink}` : styles.link
            }
          >
            Automobile
          </NavLink>
          <NavLink
            to="/prices"
            onClick={handleLinkClick}
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.activeLink}` : styles.link
            }
          >
            Prețuri
          </NavLink>
          <NavLink
            to="/aboutus"
            onClick={handleLinkClick}
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.activeLink}` : styles.link
            }
          >
            Despre noi
          </NavLink>
          <NavLink
            to="/contact"
            onClick={handleLinkClick}
            className={({ isActive }) =>
              isActive ? `${styles.link} ${styles.activeLink}` : styles.link
            }
          >
            Contacte
          </NavLink>
        </div>

        
      </div>
    </div>
  );
}
