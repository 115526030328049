import React from "react";
import styles from "./Benefits.module.css";
import mainAuto from "../../media/images/mainAuto.svg";
import carIcon from "../../media/icons/carIcon.svg";
import oclockIcon from "../../media/icons/oclockIcon.svg";
import saleIcon from "../../media/icons/saleIcon.svg";
import deliveryIcon from "../../media/icons/deliveryIcon.svg";

export default function Benefits() {
  return (
    <div className={styles.benefitsContainer}>
      <div className={styles.leftSide}>
        <img src={mainAuto} alt="auto" className={styles.leftSideImg} />
      </div>
      <div className={styles.rightSide}>
        <h2>OKRENTACAR – Cheia călătoriei tale perfecte!</h2>
        <p>
          Detalii plăcute care ne diferențiază de alte servicii. Calitatea și
          grija față de clienți sunt garanția unei colaborări permanente cu
          dumneavoastră. Ne remarcăm față de concurență datorită unei game largi
          de mașini de clasă business și unui serviciu prietenos.
        </p>
        <div className={styles.benefitsOffers}>
          <div>
            <img src={carIcon} alt="car-icon" />
            <p>Peste 50 de mașini disponibile</p>
          </div>
          <div>
            <img src={oclockIcon} alt="-time-icon" />
            <p>Timpul de procesare 10–20 de minute</p>
          </div>
          <div>
            <img src={saleIcon} alt="sale-icon" />
            <p>Oferim reduceri clienților fideli</p>
          </div>
          <div>
            <img src={deliveryIcon} alt="-delivery-icon" />
            <p>Livrare în Chisinau</p>
          </div>
        </div>
      </div>
    </div>
  );
}
