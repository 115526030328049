import axios from 'axios';

// Базовый URL для API
const API_BASE_URL = 'https://okrentacar.md/phpScripts';

export const fetchCars = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getCars.php`);
    return response.data; // Возвращаем данные, полученные с сервера
  } catch (error) {
    console.error('Error fetching cars data!', error);
    throw error; // Пробрасываем ошибку для дальнейшей обработки
  }
};

export const fetchServices = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/getServices.php`);
    return response.data; //// Возвращаем данные, полученные с сервера
  } catch (error) {
    console.error('Error fetching services data!', error);
    throw error; // Пробрасываем ошибку для дальнейшей обработки
  }  
};

export const saveReservation = async (data) => {
  const response = await axios.post(`${API_BASE_URL}/saveReservation.php`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};