import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";
import instagramIcon from "../../media/icons/instagramIcon.svg";
import facebookIcon from "../../media/icons/facebookIcon.svg";
import tiktokIcon from "../../media/icons/tiktokIcon.svg";
import logo from "../../media/logo/logo.svg";

export default function Footer() {
  return (
    <div>
      <div className={styles.footer}>
        <div className={styles.okRentaCar}>
          <img src={logo} alt="logo" />
          <p>
            <span>OkRentacar – </span> Încrederea ta la fiecare kilometru.
            Asigurăm confortul și siguranța ta pe drum.
          </p>
        </div>
        <div className={styles.rightSideFooter}>
          <div className={styles.linksContainer}>
            <h5>Link-uri utile</h5>
            <div className={styles.links}>
              <Link className={styles.link} to={"/aboutus"}>
                Despre noi
              </Link>
              <Link className={styles.link} to={"/prices"}>
                Prețuri
              </Link>
              <Link className={styles.link} to={"/autos"}>
                Automobile
              </Link>
            </div>
          </div>

          <div className={styles.contactsContainer}>
            <h5>Contacte</h5>
            <div className={styles.contacts}>
              <p>Adresă: Str. Albișoara 5</p>
              <p>Telefon: +373 61 094 444</p>
              <p>Email: okrentacar.md@gmail.com</p>
            </div>
          </div>

          <div className={styles.socialMediaContainer}>
            <h5>Rețele sociale</h5>
            <div className={styles.socialMedia}>
              <a
                href="https://www.instagram.com/okrentacar2024/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61555550489307"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
              {/* <a href="#">Telegram</a> */}
              <a href="mailto:okrentacar.md@gmail.com">Email</a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.socialMediaIcons}>
        <a
          href="https://www.tiktok.com/@okrentacar2024"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={tiktokIcon} alt="tiktok-icon" className={styles.iconSize} />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61555550489307"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={facebookIcon}
            alt="facebook-icon"
            className={styles.iconSize}
          />
        </a>
        <a
          href="https://www.instagram.com/okrentacar2024/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={instagramIcon}
            alt="instagram-icon"
            className={styles.iconSize}
          />
        </a>
      </div>

      <div className={styles.copyright}>
        <p className={styles.paragraphWithPadding}>
          Created by{" "}
          <a
            href="https://www.promoweb.md/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.promowebLink}
          >
            PromoWeb
          </a>
        </p>
        <p>Toate drepturile rezervate © 2024</p>
      </div>
    </div>
  );
}
