import React from "react";
import styles from "./Header.module.css";
import carBaner from "../../media/images/carBaner.svg";
import BookForm from "../BookForm/BookForm";

export default function Header({
  formSinglePage,
  formAutosPage,
  formPricesPage,
  headerSinglePage,
  imgSinglePage,
  hideHeading,
  hideButton,
  headerAutosPage,
  headerPricesPage,
  onDateChange
}) {
  return (
    <div
      className={`${styles.header} ${headerSinglePage} ${headerAutosPage} ${headerPricesPage}`}
    >
      <img
        className={styles.headerImage}
        src={imgSinglePage || carBaner}
        alt="car"
      />
      <div
        className={`${styles.formContainer} ${formSinglePage} ${formAutosPage} ${formPricesPage}`}
      >
        <BookForm
          formSinglePage={formSinglePage}
          formAutosPage={formAutosPage}
          formPricesPage={formPricesPage}
          hideHeading={hideHeading}
          hideButton={hideButton}
          onDateChange={onDateChange} // передаем onDateChange дальше в BookForm
        />
      </div>
    </div>
  );
}
