import React from "react";
import styles from "./OrderButton.module.css";

export default function OrderButton({ onClick, center }) {
  const buttonContainerStyle = {
    display: "flex",
    justifyContent: center ? "center" : "flex-start",
  };

  return (
    <div style={buttonContainerStyle}>
      <button className={styles.orderButton} onClick={onClick}>
        Plasează comanda
      </button>
    </div>
  );
}
