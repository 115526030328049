import React, { useState, useEffect } from "react";
import styles from "./LoadingIndicator.module.css";
import { TailSpin } from "react-loader-spinner";

export default function LoadingIndicator() {
  const [spinnerHeight, setSpinnerHeight] = useState("150"); // Начальная высота

  useEffect(() => {
    const updateHeight = () => {
      if (window.innerWidth < 840) {
        setSpinnerHeight("90"); // Высота 50px для экранов меньше 840px
      } else {
        setSpinnerHeight("150"); // Высота 150px для экранов больше 840px
      }
    };

    // Вызовем функцию при монтировании компонента
    updateHeight();

    // Добавляем обработчик события resize
    window.addEventListener("resize", updateHeight);

    // Убираем обработчик при размонтировании компонента
    return () => window.removeEventListener("resize", updateHeight);
  }, []); // Массив зависимостей пустой, эффект сработает только при монтировании

  return (
    <div className={styles.loadingContainer}>
      <TailSpin
        height={spinnerHeight} // Используем динамическую высоту
        width="100%"
        radius="9"
        color="#C2272D"
        ariaLabel="loading"
      />
    </div>
  );
}
