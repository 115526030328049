import React, { useEffect, useState } from "react";
import styles from "./OrderForm.module.css";
import OrderButton from "../OrderButton/OrderButton";
import fileIcon from "../../media/icons/fileIcon.svg";
import crossIcon from "../../media/icons/crossIcon.svg";
import useStore from "../../store"; // Импортируем Zustand store
import { saveReservation } from "../../api/api"; // Импортируем API для получения данных
import ModalWindow from "../ModalWindow/ModalWindow";
import { useForm, ValidationError } from "@formspree/react";

export default function OrderForm({ handleClose }) {
  const { rentalDetails } = useStore((state) => state); // Получаем детали аренды из Zustand

  const [formData, setFormData] = useState({
    nume: "",
    prenume: "",
    email: "",
    telefon: "Telefon", // Устанавливаем начальное значение с текстом
    zbor: "",
    mesaj: "",
    permis: null,
  });

  const [errors, setErrors] = useState({}); // Состояние для хранения ошибок

  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна
  const [successMessage, setSuccessMessage] = useState(""); // Сообщение об успехе

  const [state, handleSubmitForm] = useForm("mjkvrrng");

  useEffect(() => {}, [rentalDetails]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Логика для полей "nume" и "prenume" — разрешаем ввод только букв
    if (name === "nume" || name === "prenume") {
      const regex = /^[a-zA-Zа-яА-ЯёЁ\s]*$/; // Разрешаем только буквы и пробелы
      if (regex.test(value) || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "telefon") {
      // Логика для телефона — разрешаем ввод только цифр
      const regex = /^[0-9]*$/;
      if (regex.test(value)) {
        // Если ввели значение и это телефон
        if (value.length === 0) {
          setFormData({ ...formData, [name]: "" }); // Если поле пустое, оставляем его пустым
        } else if (value.length < 10) {
          setFormData({ ...formData, [name]: `0${value.replace(/^0/, "")}` }); // Убедимся, что номер начинается с 0
        }
      }
    } else {
      setFormData({ ...formData, [name]: value }); // Для остальных полей просто обновляем
    }
  };

  const handleFocus = (event) => {
    if (event.target.name === "telefon") {
      if (formData.telefon === "Telefon") {
        setFormData({ ...formData, telefon: "0" }); // Устанавливаем значение на 0 при фокусе
      }
    }
  };

  const handleBlur = (event) => {
    if (event.target.name === "telefon" && formData.telefon === "0") {
      setFormData({ ...formData, telefon: "Telefon" }); // Сбрасываем на "Telefon", если ничего не введено
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, permis: file });
  };

  const validateForm = () => {
    const newErrors = {};

    // Проверяем каждое поле на корректность
    if (!formData.nume) {
      newErrors.nume = "Numele este obligatoriu.";
    }

    if (!formData.prenume) {
      newErrors.prenume = "Prenumele este obligatoriu.";
    }

    if (!formData.email) {
      newErrors.email = "Emailul este obligatoriu.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Emailul este invalid.";
    }

    if (formData.telefon === "Telefon" || formData.telefon.length !== 9) {
      newErrors.telefon = "Telefonul trebuie să conțină exact 9 cifre.";
    }

    if (!formData.permis) {
      newErrors.permis = "Permisul de conducere este obligatoriu.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Возвращаем true, если ошибок нет
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Проверяем форму перед отправкой
    if (!validateForm()) {
      return; // Не отправляем форму, если есть ошибки
    }

    const dataToSend = new FormData();
    dataToSend.append("firstName", formData.nume);
    dataToSend.append("lastName", formData.prenume);
    dataToSend.append("email", formData.email);
    dataToSend.append("phone", formData.telefon);

    const pickupDateTime = `${
      rentalDetails.start.date.toISOString().split("T")[0]
    } ${rentalDetails.start.time}`;
    const returnDateTime = `${
      rentalDetails.end.date.toISOString().split("T")[0]
    } ${rentalDetails.end.time}`;

    dataToSend.append("pickupDate", pickupDateTime);
    dataToSend.append("returnDate", returnDateTime);
    dataToSend.append("pickupLocation", rentalDetails.pickupLocation || "");
    dataToSend.append("returnLocation", rentalDetails.dropoffLocation || "");
    dataToSend.append("days", rentalDetails.rentalDays || 1);
    dataToSend.append("totalPrice", rentalDetails.totalPrice);
    dataToSend.append("flightNumber", formData.zbor);
    dataToSend.append("driverLicenseImg", formData.permis);
    dataToSend.append("carCode", rentalDetails.carCode);
    dataToSend.append("message", formData.mesaj);
    dataToSend.append("status", "pending");

    if (
      rentalDetails.selectedServices &&
      rentalDetails.selectedServices.length > 0
    ) {
      rentalDetails.selectedServices.forEach((service) => {
        dataToSend.append(`selectedServices[]`, service.serviceCode); // Добавляем только serviceCode
      });
    }

    try {
      const dbResponse = await saveReservation(dataToSend);

      if (dbResponse.success) {
        setSuccessMessage("Rezervarea a fost plasată cu succes!");
        setIsModalOpen(true);

        // Отправка данных на FormSpree
        const formSpreeData = {
          nume: formData.nume,
          prenume: formData.prenume,
          email: formData.email,
          telefon: formData.telefon,
          pickupDate: pickupDateTime,
          returnDate: returnDateTime,
          pickupLocation: rentalDetails.pickupLocation || "",
          returnLocation: rentalDetails.dropoffLocation || "",
          totalPrice: rentalDetails.totalPrice,
          flightNumber: formData.zbor,
          carCode: rentalDetails.carCode,
          mesaj: formData.mesaj,
        };

        // Проверяем, есть ли выбранные сервисы и добавляем их названия
        if (
          rentalDetails.selectedServices &&
          rentalDetails.selectedServices.length > 0
        ) {
          const serviceNames = rentalDetails.selectedServices.map(
            (service) => service.nameService
          );
          formSpreeData.selectedServices = serviceNames; // Добавляем названия сервисов в formSpreeData
        }

        await fetch("https://formspree.io/f/mjkvrrng", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formSpreeData),
        });
      } else {
        alert("Eroare la plasarea rezervării.");
      }
    } catch (error) {
      console.error("Eroare la trimiterea rezervării:", error);
      alert("A apărut o eroare la plasarea rezervării.");
    }
  };
  //Метод для закрытия формы и модального окна
  const handleModalClose = () => {
    setIsModalOpen(false); // Закрываем модальное окно
    handleClose(); // Закрываем форму
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formHeader}>
        <div className={styles.formTitle}>
          <h2>Plasarea comenzii</h2>
          <h4>Îndepliniți formularul</h4>
        </div>
        <img src={crossIcon} onClick={handleClose} alt="cross-icon" />
      </div>

      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <div className={styles.fieldRowWrapper}>
          <div className={styles.inputs}>
            <p className={styles.hideParagraph}>Numele</p>
            <input
              type="text"
              name="nume"
              placeholder="Numele"
              value={formData.nume}
              onChange={handleChange}
              required
            />
            {errors.nume && <span className={styles.error}>{errors.nume}</span>}{" "}
            {/* Ошибка имени */}
          </div>
          <div className={styles.inputs}>
            <p className={styles.hideParagraph}>Prenumele</p>
            <input
              type="text"
              name="prenume"
              placeholder="Prenumele"
              value={formData.prenume}
              onChange={handleChange}
              required
            />
            {errors.prenume && (
              <span className={styles.error}>{errors.prenume}</span>
            )}{" "}
            {/* Ошибка фамилии */}
          </div>
        </div>
        <div className={styles.fieldRowWrapper}>
          <div className={styles.inputs}>
            <p className={styles.hideParagraph}>Email</p>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className={styles.error}>{errors.email}</p>}{" "}
            {/* Ошибка email */}
          </div>
          <div className={styles.inputs}>
            <p className={styles.hideParagraph}>Telefon</p>{" "}
            <input
              type="tel"
              name="telefon"
              placeholder={formData.telefon === "" ? "Telefon" : "0"} // Отображаем "Telefon" если поле пустое
              value={formData.telefon === "0" ? "" : formData.telefon}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              required
            />
            {errors.telefon && <p className={styles.error}>{errors.telefon}</p>}{" "}
            {/* Ошибка телефона */}
          </div>
        </div>
        <div className={styles.fieldRowWrapper}>
          <div className={styles.inputs}>
            <p className={styles.hideParagraph}>Numărul zborului</p>
            <input
              type="text"
              name="zbor"
              placeholder="Numărul zborului"
              value={formData.zbor}
              onChange={handleChange}
            />
            {errors.zbor && <p className={styles.error}>{errors.zbor}</p>}{" "}
            {/* Ошибка номера рейса */}
          </div>
          <div className={styles.inputs}>
            <p className={styles.hideParagraph}>Mesaj</p>
            <textarea
              name="mesaj"
              placeholder="Mesaj"
              value={formData.mesaj}
              onChange={handleChange}
            />
            {errors.mesaj && <p className={styles.error}>{errors.mesaj}</p>}{" "}
            {/* Ошибка сообщения */}
          </div>
        </div>
        <div className={styles.fieldRowWrapper}>
          <h4 className={styles.comfortaaFont}>Permisul de conducere</h4>
          <div>
            <div className={styles.lastInput}>
              <img className={styles.fileIcon} src={fileIcon} alt="file-icon" />
              <label className={styles.customFileInput}>
              <span>
                {formData.permis
                  ? formData.permis.name.length > 8
                    ? `${formData.permis.name.slice(0, 5)}...`
                    : formData.permis.name
                  : "File"}
              </span>

                <input
                  type="file"
                  name="permis"
                  onChange={handleFileChange}
                  required
                />
              </label>
              <div className={styles.orderButton}>
                <OrderButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      <ModalWindow
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        message={successMessage}
      />
    </div>
  );
}
