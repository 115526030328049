import React from "react";
import styles from "./AboutUsPage.module.css";

export default function AboutUsPage() {
  return (
    <div className={styles.backgroundImg}>
      <div className={styles.content}>
        <h1>Despre noi</h1>
        <div className={styles.welcomeSection}>
          <h4>Bine ați venit la OK Rent a Car! </h4>
          <p className={styles.description}>
            La OK Rentacar, suntem dedicați să vă oferim o experiență de
            închiriere auto de neuitat, combinând profesionalismul cu pasiunea
            pentru calitate și confort. Cu o gamă diversificată de vehicule, de
            la mașini economice până la SUV-uri de lux, ne asigurăm că aveți la
            dispoziție exact mașina de care aveți nevoie, indiferent de ocazie.
          </p>
        </div>

        <div className={styles.aboutUsSection}>
          <h4 className={styles.sectionTitle}>Cine suntem?</h4>
          <p className={styles.description}>
            Înființată în 2023, OK Rentacar a devenit rapid un lider de
            încredere pe piața închirierilor auto din Republica Moldova.
            Misiunea noastră este să oferim servicii de înaltă calitate,
            caracterizate prin transparență, integritate și atenție la detalii.
          </p>
        </div>

        <div className={styles.whyChooseUsSection}>
          <h4 className={styles.sectionTitle}>De ce să ne alegeți pe noi?</h4>
          <ul className={styles.benefitsList}>
            <li className={styles.benefitItem}>
              Flotă variată și modernă: Dispunem de o gamă largă de vehicule, de
              la mașini compacte ideale pentru oraș, până la SUV-uri și mașini
              de lux pentru călătorii mai lungi sau evenimente speciale.
            </li>
            <li className={styles.benefitItem}>
              Prețuri accesibile: Oferim pachete de preț competitive, adaptate
              oricărui buget, fără a face compromisuri asupra calității.
            </li>
            <li className={styles.benefitItem}>
              Servicii de excepție: Echipa noastră este mereu pregătită să vă
              ofere asistență personalizată și soluții rapide pentru orice
              nevoie.
            </li>
            <li className={styles.benefitItem}>
              Flexibilitate și confort: Oferim opțiuni flexibile de închiriere,
              inclusiv preluare și predare în diferite locații pentru a vă face
              experiența cât mai convenabilă.
            </li>
            <li className={styles.benefitItem}>
              Siguranță garantată: Toate mașinile noastre sunt întreținute
              riguros și inspectate regulat pentru a vă asigura o călătorie fără
              griji.
            </li>
          </ul>
        </div>

        <div className={styles.missionSection}>
          <h4 className={styles.sectionTitle}>Misiunea noastră</h4>
          <p className={styles.description}>
            Scopul nostru este să vă oferim cele mai bune soluții de mobilitate,
            punând accent pe satisfacția clientului și excelență în servicii.
            Prin inovare constantă și atenție la feedback-ul clienților noștri,
            ne străduim să stabilim noi standarde în industria închirierilor
            auto.
          </p>
        </div>

        <div className={styles.contactSection}>
          <h4 className={styles.sectionTitle}>Contactați-ne</h4>
          <p className={styles.description}>
            Suntem aici pentru a vă ajuta! Pentru orice întrebare sau pentru a
            face o rezervare, nu ezitați să ne contactați.
          </p>
        </div>
      </div>

      <h2 className={styles.thankYou}>
        Vă mulțumim că ați ales OK Rent a Car! Suntem nerăbdători să fim
        partenerul dumneavoastră de drum.
      </h2>
    </div>
  );
}
