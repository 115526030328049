import React from "react";
import styles from "./GoogleMap.module.css";

export default function GoogleMap() {
  return (
    <div className={styles.mapBlock}>
      <h2>Adresa noastră</h2>
      <iframe
        title="google_map"
        className={styles.googleMap}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2720.255917392576!2d28.854185011964404!3d47.01558142848283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97c16f9564c17%3A0x5ba19ac0e35e6968!2zU3RyYWRhIEFsYmnFn29hcmEgNSwgQ2hpyJlpbsSDdSwgTW9sZG92YQ!5e0!3m2!1sro!2s!4v1728647432245!5m2!1sro!2s"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}