import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header.jsx";
import whiteCar from "../../media/images/whiteCar.svg";
import filterIcon from "../../media/icons/filterIcon.svg";
import thickCrossIcon from "../../media/icons/thickCrossIcon.svg";
import styles from "./AutosPage.module.css";
import ProductsContainer from "../../components/ProductsContainer/ProductsContainer.jsx";
import SortAutosPage from "../../components/SortAutosPage/SortAutosPage.jsx";
import FilterAutosPage from "../../components/FilterAutosPage/FilterAutosPage.jsx";

export default function AutosPage() {
  const [sortOrder, setSortOrder] = useState("Recomandat"); // Состояние для хранения текущего порядка сортировки
  const [filters, setFilters] = useState({
    typeFuel: [],
    transmission: [],
    typeBody: [],
  }); // Состояние для фильтров

  const [columns, setColumns] = useState(3); // Состояние для колонок
  const [buttonMargin, setButtonMargin] = useState("200px");
  const [isOpen, setIsOpen] = useState(false);
  const [isHide, setIsHide] = useState(true);

  const toggleMenu = () => {
    // Переключаем меню только на экранах менее 1024px
    if (window.innerWidth <= 1024) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  };

  useEffect(() => {
    // Функция для обновления количества колонок в зависимости от ширины экрана
    const updateColumns = () => {
      const windowWidth = window.innerWidth;
  
      // Устанавливаем количество колонок и отступ кнопок в зависимости от ширины экрана
      if (windowWidth > 1350) {
        setColumns(3);
        setButtonMargin("200px");
      } else if (windowWidth > 1205) {
        setColumns(2);
        setButtonMargin("150px");
      } else if (windowWidth > 1024) {
        setColumns(1);
        setButtonMargin("100px");
      } else if (windowWidth > 830) {
        setColumns(2);
        setButtonMargin("50px");
      } else {
        setColumns(1);
        setButtonMargin("50px");
      }
  
      // Если экран больше 1024px, фильтр всегда открыт
      if (windowWidth > 1024) {
        setIsOpen(true);
      }
    };
  
    updateColumns(); // Устанавливаем начальное значение колонок
  
    window.addEventListener("resize", updateColumns); // Добавляем обработчик для изменения ширины
  
    return () => window.removeEventListener("resize", updateColumns); // Удаляем обработчик при размонтировании компонента
  }, []);

  // Функция для обновления фильтров
  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType].includes(value)) {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (item) => item !== value
        );
      } else {
        updatedFilters[filterType].push(value);
      }
      return updatedFilters;
    });
  };

  return (
    <div>
      {/* Компонент заголовка с кастомными стилями */}
      {isHide && (
        <Header
          headerAutosPage={styles.customHeaderAutosPage}
          formAutosPage={styles.customFormAutosPage}
          imgSinglePage={whiteCar}
          hideHeading={true}
        />
      )}

      {/* Основной контент страницы */}
      <div className={styles.mainContainer}>
        <div
          className={`${styles.leftSideContainer} ${isOpen ? styles.open : ""}`}
        >
          <h2>Automobile</h2>
          {isOpen && (
            <>
              <div className={styles.filterHeader}>
                <h2 className={styles.hideH2}>Filtru</h2>
                <img
                  src={thickCrossIcon} // Переключаем иконку в зависимости от состояния
                  alt="cross-icon"
                  onClick={toggleMenu} // Добавляем обработчик клика
                  className={styles.thickCrossIcon} // Применяем разные классы
                />
              </div>
              <FilterAutosPage
                filters={filters}
                onFilterChange={handleFilterChange}
              />
            </>
          )}
        </div>

        {/* Основной контейнер страницы */}
        {isHide && (
          <div className={styles.rightSideContainer}>
            <div className={styles.filterSide}>
              <div className={styles.upperFilterSide}>
                <h2 className={styles.hidenParagraph}>Automobile</h2>
                <img
                  src={filterIcon} // Переключаем иконку в зависимости от состояния
                  alt="filter-icon"
                  onClick={toggleMenu} // Добавляем обработчик клика
                  className={styles.filterIcon} // Применяем разные классы
                />{" "}
              </div>
              {/* Компонент фильтров, который обновляет порядок сортировки */}
              <SortAutosPage setSortOrder={setSortOrder} />
            </div>

            {/* Компонент с отображением списка продуктов */}
            <div className={styles.productsContainerWrapper}>
              <ProductsContainer
                columns={columns}
                buttonMargin={buttonMargin}
                sortOrder={sortOrder}
                filters={filters}
                initialVisibleCars={6}
                loadMoreIncrement={3}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
