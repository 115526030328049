import React, { useEffect, useState } from "react";
import styles from "./ProductsContainer.module.css";
import ProductCard from "../ProductCard/ProductCard"; // Импортируем компонент ProductCard
import { fetchCars } from "../../api/api"; // Импортируем API для получения данных
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator"; 

export default function ProductsContainer({
  columns,
  buttonMargin,
  sortOrder,
  filters,
  initialVisibleCars = 8,
  loadMoreIncrement = 4,
}) {
  // Добавляем filters в пропсы
  const [cars, setCars] = useState([]); // Состояние для хранения данных о машинах
  const [visibleCars, setVisibleCars] = useState(initialVisibleCars); // Начальное количество отображаемых машин

  const [isLoading, setIsLoading] = useState(true); // Состояние для отслеживания загрузки данных
  useEffect(() => {
    const loadCars = async () => {
      try {
        setIsLoading(true); // Устанавливаем состояние загрузки
        const data = await fetchCars();
        setCars(data); // Сохраняем данные в состояние
      } catch (error) {
        console.error("Error fetching cars data:", error);
      } finally {
        setIsLoading(false); // Выключаем индикатор загрузки после завершения загрузки данных
      }
    };

    loadCars();
  }, []);


  // Функция для сортировки автомобилей
  const getSortedCars = (carsToSort) => {
    const carsCopy = [...carsToSort]; // Копируем массив машин
    if (sortOrder === "Preț minim") {
      return carsCopy.sort((a, b) => a.days30plus - b.days30plus);
    } else if (sortOrder === "Preț maxim") {
      return carsCopy.sort((a, b) => b.days30plus - a.days30plus);
    }
    return carsCopy; // Возвращаем оригинальный порядок, если сортировка не требуется
  };

  // Функция для фильтрации автомобилей по выбранным фильтрам
  const getFilteredCars = () => {
    // Если фильтры отсутствуют, возвращаем все автомобили
    if (!filters) return cars;

    // Используем метод filter для создания нового массива, содержащего только те автомобили, которые соответствуют выбранным фильтрам
    return cars.filter((car) => {
      // Проверяем, соответствует ли тип топлива автомобиля фильтрам
      const matchestypeFuel =
        filters?.typeFuel?.length === 0 || // Если фильтр по типу топлива не выбран (пустой массив)
        filters?.typeFuel?.includes(car.typeFuel); // Или если тип топлива автомобиля есть в выбранных фильтрах

      // Проверяем, соответствует ли трансмиссия автомобиля фильтрам
      const matchesTransmission =
        filters?.transmission?.length === 0 || // Если фильтр по трансмиссии не выбран (пустой массив)
        filters?.transmission?.includes(car.transmission); // Или если трансмиссия автомобиля есть в выбранных фильтрах

      // Проверяем, соответствует ли тип кузова автомобиля фильтрам
      const matchestypeBody =
        filters?.typeBody?.length === 0 || // Если фильтр по типу кузова не выбран (пустой массив)
        filters?.typeBody?.includes(car.typeBody); // Или если тип кузова автомобиля есть в выбранных фильтрах

      // Возвращаем true, если все условия фильтрации выполнены, то автомобиль будет включен в результирующий массив
      return (
        matchestypeFuel && // Проверка по типу топлива
        matchesTransmission && // Проверка по трансмиссии
        matchestypeBody // Проверка по типу кузова
      );
    });
  };

  // Функция для фильтрации и сортировки автомобилей
  const getFilteredAndSortedCars = () => {
    const filteredCars = getFilteredCars(); // Сначала фильтруем
    return getSortedCars(filteredCars); // Затем сортируем
  };

  // Функция для загрузки большего количества машин
  const loadMoreCars = () => {
    setVisibleCars(
      (prevVisibleCars) =>
        Math.min(prevVisibleCars + loadMoreIncrement, cars.length)
    );
  };

  // Динамическое количество колонок для сетки
  const gridStyle = {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator /> // Показываем индикатор загрузки, пока данные загружаются
      ) : cars.length === 0 || getFilteredAndSortedCars().length === 0 ? (
        <p className={styles.noAutosParagraph}>
          Ne pare rău, nu sunt disponibile astfel de automobile la moment.
        </p>
      ) : (
        <div className={styles.productsContainer} style={gridStyle}>
          {Array.isArray(cars) &&
            getFilteredAndSortedCars()
              .slice(0, visibleCars)
              .map((car) => <ProductCard key={car.carCode} car={car} />)}
        </div>
      )}

      {visibleCars < getFilteredAndSortedCars().length && !isLoading && (
        <button
          className={styles.moreAutosButton}
          onClick={loadMoreCars}
          style={{ marginBottom: buttonMargin }}
        >
          Mai mult
        </button>
      )}
    </div>
  );
}