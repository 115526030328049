import React from "react";
import styles from "./ContactPage.module.css";
import telephoneIcon from "../../media/icons/telephoneIcon.svg";
import adressIcon from "../../media/icons/adressIcon.svg";
import gmailIcon from "../../media/icons/gmailIcon.svg";
import ContactForm from "../../components/ContactForm/ContactForm";
import GoogleMap from "../../components/GoogleMap/GoogleMap";

export default function ContactPage() {
  return (
    <div>
      <div className={styles.contactsContainer}>
        <div className={styles.leftSideContainer}>
          <h2>Contacte</h2>

          <div className={styles.workingHours}>
            <h4>Program de lucru:</h4>
            <p>Luni - Duminică: 24/24</p>
          </div>

          <div className={styles.contactDetails}>
            <div className={styles.contactItem}>
              <img
                src={telephoneIcon}
                alt="telephone-icon"
                className={styles.iconSize}
              />
              <a href="tel:+37361094444">
                <p className={styles.innerContent}>+373 61 094 444</p>
              </a>
            </div>
            <div className={styles.contactItem}>
              <img
                src={adressIcon}
                alt="adress-icon"
                className={styles.iconSize}
              />
              <a
                href="https://www.google.com/maps/search/?api=1&query=Strada+Albișoara+5,+Chișinău,+Moldova"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.contactLink}
              >
                <p className={styles.innerContent}>
                  Chisinau, str. Albișoara 5
                </p>
              </a>
            </div>
            <div className={styles.contactItem}>
              <img
                src={gmailIcon}
                alt="mail-icon"
                className={styles.iconSize}
              />
              <a href="mailto:info@nextrentacar.md">
                <p className={styles.innerContent}>okrentacar.md@gmail.com</p>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.rightSideContainer}>
          <ContactForm />
        </div>
      </div>
      <div className={styles.googleMaps}>
        <GoogleMap />
      </div>
    </div>
  );
}
