import React from "react";
import styles from "./FilterAutosPage.module.css";
import CustomCheckbox from "../CustomCheeckbox/CustomCheeckbox"; // Импортируем кастомный чекбокс

export default function FilterAutosPage({ filters, onFilterChange }) {
  const typeFuel = filters?.typeFuel || []; // Получаем типы топлива из фильтров или устанавливаем пустой массив
  const transmission = filters?.transmission || []; // Получаем типы трансмиссий из фильтров или устанавливаем пустой массив
  const typeBody = filters?.typeBody || []; // Получаем типы кузовов из фильтров или устанавливаем пустой массив

  return (
    <div>
      {/* Левая панель с фильтрами */}
      <div className={styles.leftContainer}>
        {/* Фильтры по типу топлива */}
        <div className={styles.leftContainerContent}>
          <h4 className={styles.title}>Tipul combustibilului</h4>
          <div>
            <CustomCheckbox
              checked={typeFuel.includes("Benzin")} // Проверяем, включен ли тип топлива 'Benzin'
              onChange={() => onFilterChange("typeFuel", "Benzin")} // Обработчик изменения состояния фильтра
              label="Benzin"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeFuel.includes("Diesel")} // Проверяем, включен ли тип топлива 'Diesel'
              onChange={() => onFilterChange("typeFuel", "Diesel")} // Обработчик изменения состояния фильтра
              label="Diesel"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeFuel.includes("Electric")} // Проверяем, включен ли тип топлива 'Electric'
              onChange={() => onFilterChange("typeFuel", "Electric")} // Обработчик изменения состояния фильтра
              label="Electric"
            />
          </div>
        </div>

        {/* Фильтры по типу трансмиссии */}
        <h4 className={styles.title}>Transmisia</h4>
        <div className={styles.leftContainerContent}>
          <div>
            <CustomCheckbox
              checked={transmission.includes("Automat")} // Проверяем, включен ли тип трансмиссии 'Automat'
              onChange={() => onFilterChange("transmission", "Automat")} // Обработчик изменения состояния фильтра
              label="Automat"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={transmission.includes("Manual")} // Проверяем, включен ли тип трансмиссии 'Manual'
              onChange={() => onFilterChange("transmission", "Manual")} // Обработчик изменения состояния фильтра
              label="Manual"
            />
          </div>
        </div>

        {/* Фильтры по категории автомобиля */}
        <h4 className={styles.title}>Categoria de automobile</h4>
        <div className={styles.leftContainerContent}>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Hatchback")} // Проверяем, включен ли тип кузова 'Hatchback'
              onChange={() => onFilterChange("typeBody", "Hatchback")} // Обработчик изменения состояния фильтра
              label="Hatchback"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("SUV")} // Проверяем, включен ли тип кузова 'SUV'
              onChange={() => onFilterChange("typeBody", "SUV")} // Обработчик изменения состояния фильтра
              label="SUV"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Minivan")} // Проверяем, включен ли тип кузова 'Minivan'
              onChange={() => onFilterChange("typeBody", "Minivan")} // Обработчик изменения состояния фильтра
              label="Minivan"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Crossover")} // Проверяем, включен ли тип кузова 'Crossover'
              onChange={() => onFilterChange("typeBody", "Crossover")} // Обработчик изменения состояния фильтра
              label="Crossover"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Furgon")} // Проверяем, включен ли тип кузова 'Furgon'
              onChange={() => onFilterChange("typeBody", "Furgon")} // Обработчик изменения состояния фильтра
              label="Furgon"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Sedan")} // Проверяем, включен ли тип кузова 'Sedan'
              onChange={() => onFilterChange("typeBody", "Sedan")} // Обработчик изменения состояния фильтра
              label="Sedan"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Universal")} // Проверяем, включен ли тип кузова 'Universal'
              onChange={() => onFilterChange("typeBody", "Universal")} // Обработчик изменения состояния фильтра
              label="Universal"
            />
          </div>
          <div>
            <CustomCheckbox
              checked={typeBody.includes("Pickup")} // Проверяем, включен ли тип кузова 'Pickup'
              onChange={() => onFilterChange("typeBody", "Pickup")} // Обработчик изменения состояния фильтра
              label="Pickup"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
