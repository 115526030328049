import React from "react";
import styles from "./ProductCard.module.css";
import { Link } from "react-router-dom";
import useStore from "../../store";

export default function ProductCard({ car }) {
  const { rentalDetails } = useStore(); // Доступ к rentalDetails через useStore
  const rentalDays = rentalDetails.rentalDays; // Получение rentalDays из глобального состояния
  
  return (
    <div className={styles.productCard}>
      {/* Отображение изображения автомобиля */}
      <img
        src={`https://okrentacar.md/admin/cars/uploads/${car.carImage}`}
        alt="test-car"
      />
      <div className={styles.productInfo}>
        {/* Название автомобиля */}
        <h4>{car.name}</h4>

        {/* Характеристики автомобиля */}
        <div>

        <p>
          An fabricație: <span>{car.year}</span>
        </p>
        <p>
          Capacitate motor: <span>{car.capacityMotor}</span>
        </p>
        <p>
          Tip carburant: <span>{car.typeFuel}</span>
        </p>
        <p>
          Transmisie: <span>{car.transmission}</span>
        </p>
        </div>

        {/* Цена аренды */}
        <p className={styles.lastParagraph}>
        {rentalDays && rentalDays > 1 ? (
            <>
              <span>{car.days30plus * rentalDays}€</span> pentru {rentalDays} zile
            </>
          ) : (
            <>
              de la <span>{car.days30plus}€</span> / zi
            </>
          )}
        </p>
      </div>

      {/* Ссылка на страницу с деталями автомобиля */}
      <Link to={`/product/${car.carCode}`}>
        <button className={styles.linkButton}>Deschide</button>
      </Link>
    </div>
  );
}