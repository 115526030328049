import React, { useState } from "react";
import styles from "./BurgerMenu.module.css";
import burgerMenuIcon from "../../media/icons/burgerMenuIcon.svg";
import thickCrossIcon from "../../media/icons/thickCrossIcon.svg";
import telephoneIcon from "../../media/icons/telephoneIcon.svg";
import adressIcon from "../../media/icons/adressIcon.svg";
import { NavLink } from "react-router-dom"; // Import NavLink

export default function BurgerMenu() {
  const [isOpen, setIsOpen] = useState(false); // Состояние для открытия/закрытия меню

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Переключение состояния
  };

  const closeMenu = () => {
    setIsOpen(false); // Закрытие меню
  };

  return (
    <div className={styles.burgerMenu}>
      <img
        src={burgerMenuIcon}
        alt="burger-menu-icon"
        className={styles.burgerIcon}
        onClick={toggleMenu}
      />
      {isOpen && (
        <>
          {/* Overlay */}
          <div className={styles.overlay} onClick={closeMenu}></div>
          <div className={styles.menu}>
            <img
              src={thickCrossIcon}
              className={styles.crossIcon}
              alt="cross-icon"
              onClick={closeMenu}
            />
            <nav className={styles.navLinks}>
              <NavLink
                to="/"
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.activeLink}` : styles.link
                }
              >
                Pagina principală
              </NavLink>
              <NavLink
                to="/autos"
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.activeLink}` : styles.link
                }
              >
                Automobile
              </NavLink>
              <NavLink
                to="/prices"
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.activeLink}` : styles.link
                }
              >
                Prețuri
              </NavLink>
              <NavLink
                to="/aboutus"
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.activeLink}` : styles.link
                }
              >
                Despre noi
              </NavLink>
              <NavLink
                to="/contact"
                onClick={closeMenu}
                className={({ isActive }) =>
                  isActive ? `${styles.link} ${styles.activeLink}` : styles.link
                }
              >
                Contacte
              </NavLink>
            </nav>
            <div className={styles.contactsContainer}>
              <div className={styles.contactItem}>
                <img
                  src={telephoneIcon}
                  alt="telephone-icon"
                  className={styles.iconSize}
                />
                <a href="tel:+37361094444" className={styles.contactLink}>
                  <span>061 </span>094 444
                </a>
              </div>
              <div className={styles.contactItem}>
                <img
                  src={adressIcon}
                  alt="adress-icon"
                  className={styles.iconSize}
                />
                <a
                  href="https://www.google.com/maps/search/?api=1&query=Strada+Albișoara+5,+Chișinău,+Moldova"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.contactLink}
                >
                  str. Albișoara 5
                </a>{" "}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
