import React, { useEffect, useState } from "react";
import styles from "./PricesPage.module.css";
import Header from "../../components/Header/Header";
import whiteCar from "../../media/images/whiteCar.svg";
import ProductsContainerWithPrices from "../../components/ProductsContainerWithPrices/ProductsContainerWithPrices";

export default function PricesPage() {
  // Создаем состояние для отслеживания активной кнопки фильтра
  const [activeButton, setActiveButton] = useState("Toate Tipurile");
  const [filters, setFilters] = useState({
    typeBody: [],
  });

  // Массив кнопок для фильтрации по типу автомобиля
  const buttons = [
    "Toate Tipurile",
    "Minivan",
    "Crossover",
    "Sedan",
    "Hatchback",
    "SUV",
    "Universal",
    "Pickup",
    "Furgon",
  ];

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 840);

  // Следим за изменением ширины экрана
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 840);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Проверка при загрузке страницы

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Функция для обновления фильтров
  const handleFilterChange = (value) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      // Если выбран фильтр "Toate Tipurile", снимаем все остальные фильтры
      if (value === "Toate Tipurile") {
        updatedFilters.typeBody = []; // Очищаем все выбранные типы
      } else {
        // Если выбраны другие фильтры, снимаем "Toate Tipurile"
        if (updatedFilters.typeBody.includes("Toate Tipurile")) {
          updatedFilters.typeBody = updatedFilters.typeBody.filter(
            (item) => item !== "Toate Tipurile"
          );
        }

        // Добавляем или убираем выбранный фильтр
        if (updatedFilters.typeBody.includes(value)) {
          updatedFilters.typeBody = updatedFilters.typeBody.filter(
            (item) => item !== value
          );
        } else {
          updatedFilters.typeBody.push(value);
        }
      }

      return updatedFilters;
    });
  };

  return (
    <div>
      {/* Компонент заголовка с кастомными стилями для страницы цен */}
      <Header
        headerPricesPage={styles.customHeaderPricesPage}
        formPricesPage={styles.customFormPricesPage}
        imgSinglePage={whiteCar}
        hideHeading={true}
        hideButton={isSmallScreen} // скрываем кнопку
      />

      {/* Основной контейнер страницы цен */}
      <div className={styles.pricesPageContainer}>
        {/* Секция заголовка с описанием цен на аренду автомобилей */}
        <div className={styles.headerSection}>
          <h2>Preturi chirie auto OK Rent a Car</h2>
          <p>
            Oferim prețuri competitive pentru închirierea auto, începând de la
            doar 35€ pe zi.
          </p>
        </div>

        {/* Блок кнопок для фильтрации типов автомобилей */}
        <div className={styles.filterButtons}>
          {buttons.map((button) => (
            <button
              key={button} // Уникальный ключ для каждой кнопки
              className={
                filters.typeBody.includes(button) ||
                (button === "Toate Tipurile" && filters.typeBody.length === 0)
                  ? styles.active
                  : ""
              }
              // Обработчик нажатия для смены активной кнопки
              onClick={() => handleFilterChange(button)}
            >
              <span>{button}</span> {/* Отображение текста кнопки */}
            </button>
          ))}
        </div>
      </div>
      <ProductsContainerWithPrices filters={filters} />
    </div>
  );
}
