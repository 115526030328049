import React, { useState, useEffect, useRef } from "react";
import styles from "./CustomTimePicker.module.css";
import timeIcon from "../../media/icons/timeIcon.svg";

const CustomTimePicker = ({ value, onChange, placeholder }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedHour, setSelectedHour] = useState(value ? value.split(":")[0] : "");
  const [selectedMinute, setSelectedMinute] = useState(value ? value.split(":")[1] : "");
  const [displayText, setDisplayText] = useState(placeholder || "Ora");
  const timePickerRef = useRef(null);

  // Закрытие выпадающего списка при клике вне его
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (timePickerRef.current && !timePickerRef.current.contains(event.target)) {
        setShowDropdown(false);
        if (!selectedHour && !selectedMinute) {
          setDisplayText(placeholder || "Ora");
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedHour, selectedMinute, placeholder]);

  // Обновление времени при изменении значений
  useEffect(() => {
    if (value) {
      const [hour, minute] = value.split(":");
      setSelectedHour(hour);
      setSelectedMinute(minute);
      setDisplayText(`${hour}:${minute}`);
    }
  }, [value]);

  // Обработчики изменения часов и минут с вызовом onChange для передачи значения вверх
  const handleHourChange = (hour) => {
    setSelectedHour(hour);
    const newTime = `${hour}:${selectedMinute || "00"}`;
    onChange(newTime); // Передаем новое значение времени в родительский компонент
    setDisplayText(newTime);
  };

  const handleMinuteChange = (minute) => {
    setSelectedMinute(minute);
    const newTime = `${selectedHour || "00"}:${minute}`;
    onChange(newTime); // Передаем новое значение времени в родительский компонент
    setDisplayText(newTime);
    setShowDropdown(false);
  };

  const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, "0"));
  const minutes = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

  return (
    <div className={styles.timeWrapper} ref={timePickerRef}>
      <img className={styles.timeIcon} src={timeIcon} alt="time-icon" />
      <input
        type="text"
        value={displayText}
        onClick={() => {
          if (showDropdown && !selectedHour && !selectedMinute) {
            setDisplayText(placeholder || "Ora");
          } else if (!showDropdown && !selectedHour && !selectedMinute) {
            setDisplayText("- - : - -");
          }
          setShowDropdown(!showDropdown);
        }}
        readOnly
        required
        className={styles.input}
      />
      {showDropdown && (
        <div className={styles.timeDropdown}>
          <div className={styles.hoursWrapper}>
            {hours.map((hour) => (
              <div
                key={hour}
                className={styles.hourOption}
                onClick={() => handleHourChange(hour)}
              >
                {hour}
              </div>
            ))}
          </div>
          <div className={styles.minutesWrapper}>
            {minutes.map((minute) => (
              <div
                key={minute}
                className={styles.minuteOption}
                onClick={() => handleMinuteChange(minute)}
              >
                {minute}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTimePicker;
