import React from "react";
import vIcon from "../../media/icons/vIcon.svg";
import styles from "./CustomCheckbox.module.css"; // Импортируем стили

const CustomCheckbox = ({ checked, onChange, label }) => {
  return (
    <label className={styles.checkboxLabel}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={styles.checkboxInput}
      />
      <span className={styles.checkboxCustom}>
        {checked && <img src={vIcon} alt="v-icon" className={styles.vIcon} />}
      </span>
      {label}
    </label>
  );
};

export default CustomCheckbox;
