import React, { useCallback, useEffect, useState } from "react";
import styles from "./SingleProductPage.module.css";
import Header from "../../components/Header/Header";
import icon1 from "../../media/iconsSinglePage/icon1.svg";
import icon2 from "../../media/iconsSinglePage/icon2.svg";
import icon3 from "../../media/iconsSinglePage/icon3.svg";
import icon4 from "../../media/iconsSinglePage/icon4.svg";
import icon5 from "../../media/iconsSinglePage/icon5.svg";
import icon6 from "../../media/iconsSinglePage/icon6.svg";
import v from "../../media/iconsSinglePage/v.svg";
import whiteCar from "../../media/images/whiteCar.svg";
import ModalWindow from "../../components/ModalWindow/ModalWindow.jsx";
import OrderButton from "../../components/OrderButton/OrderButton.jsx";
import { useParams } from "react-router-dom"; // Импортируем useParams для получения id из URL
import { fetchCars } from "../../api/api"; // Обновленный путь к api.js
import { fetchServices } from "../../api/api"; // Обновленный путь к api.js
import useStore from "../../store"; // Импортируем Zustand store
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator.jsx";

export default function SingleProductPage() {
  const { carCode } = useParams(); // Получаем carCode из URL

  const [car, setCar] = useState(null); // Состояние для данных машины
  const [services, setServices] = useState([]); // Состояние для данных услуг
  const [priceList, setPriceList] = useState(null); // Состояние для данных цен
  const [selectedServices, setSelectedServices] = useState([]); // Состояние для выбранных сервисов
  const [rentalDays, setRentalDays] = useState(1); // Состояние для дней
  const [openModal, setOpenModal] = useState(false); // Состояние для управления модальным окном

  // Zustand store actions
  const updateCarCode = useStore((state) => state.updateCarCode);
  const updateTotalPrice = useStore((state) => state.updateTotalPrice);
  const updateSelectedServices = useStore(
    (state) => state.updateSelectedServices
  );
  const isBookFormComplete = useStore((state) => state.isBookFormComplete); // Проверка формы из store

  // Открытие модального окна
  const handleOpenModal = () => setOpenModal(true);
  // Закрытие модального окна
  const handleCloseModal = () => setOpenModal(false);

  const [openWarningModal, setOpenWarningModal] = useState(false);

  // Плавная прокрутка наверх
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleOpenWarningModal = () => setOpenWarningModal(true);
  const handleCloseWarningModal = () => {
    scrollToTop(); // Прокрутка к верху перед закрытием модального окна
    setOpenWarningModal(false); // Закрытие предупреждающего окна
  };
  // Затем в useEffect, можно добавить обработчик
  useEffect(() => {
    if (!openWarningModal) {
      window.scrollTo({ top: 0 });
    }
  }, [openWarningModal]);

  // Обработка изменения чекбокса для выбора/снятия услуги
  const handleServiceChange = (service) => {
    const isSelected = selectedServices.includes(service);

    if (isSelected) {
      // Если сервис уже выбран, убираем его из списка
      setSelectedServices((prevSelected) =>
        prevSelected.filter((s) => s.serviceCode !== service.serviceCode)
      );
    } else {
      // Иначе добавляем его в список
      setSelectedServices((prevSelected) => [...prevSelected, service]);
    }
  };

  // Рассчитываем общую стоимость выбранных услуг
  const totalServicePrice = selectedServices.reduce(
    (total, service) => total + Number(service.priceService) * rentalDays,
    0
  );

  const totalCarPrice = () => {
    // Проверяем, загружен ли priceList
    if (!priceList) {
      return 0; // Возвращаем 0 или любое значение по умолчанию
    }

    // Возвращаем стоимость аренды автомобиля в зависимости от количества дней аренды
    if (rentalDays <= 2) {
      return priceList.days1to2 * rentalDays;
    } else if (rentalDays <= 7) {
      return priceList.days3to7 * rentalDays;
    } else if (rentalDays <= 15) {
      return priceList.days8to15 * rentalDays;
    } else if (rentalDays <= 21) {
      return priceList.days16to21 * rentalDays;
    } else if (rentalDays <= 29) {
      return priceList.days22to29 * rentalDays;
    } else {
      return priceList.days30plus * rentalDays;
    }
  };

  // Проверяем, загружены ли данные прежде чем рассчитывать общую стоимость
  const totalPrice = priceList ? totalCarPrice() + totalServicePrice : 0;

  // Используем useCallback для оптимизации функции загрузки данных автомобиля,
  // чтобы функция не пересоздавалась при каждом рендере, если carCode не изменился
  const loadCarData = useCallback(async () => {
    try {
      // Получаем данные о машинах с сервера
      const response = await fetchCars();

      // Проверяем, является ли ответ массивом
      if (!Array.isArray(response)) {
        throw new Error("Expected response to be an array");
      }

      // Находим нужный автомобиль по carCode
      const foundCar = response.find((car) => car.carCode === carCode);

      // Если машина не найдена, выбрасываем ошибку
      if (!foundCar) {
        throw new Error("Car not found");
      }

      // Устанавливаем данные автомобиля в состояние
      setCar(foundCar);

      // Формируем объект с ценами для разных периодов аренды
      const priceList = {
        days1to2: foundCar.days1to2,
        days3to7: foundCar.days3to7,
        days8to15: foundCar.days8to15,
        days16to21: foundCar.days16to21,
        days22to29: foundCar.days22to29,
        days30plus: foundCar.days30plus,
      };

      // Устанавливаем данные прайс-листа в состояние
      setPriceList(priceList);
    } catch (error) {
      // Логируем ошибку в консоль, если данные не удалось загрузить
      console.error("Error loading car data!", error);
    }
  }, [carCode]); // Добавляем carCode как зависимость, чтобы функция пересоздавалась при его изменении

  // Функция для загрузки данных сервисов
  const loadServicesData = async () => {
    try {
      const response = await fetchServices();

      if (!Array.isArray(response)) {
        throw new Error("Expected response to be an array");
      }

      setServices(response);
    } catch (error) {
      console.error("Error loading services data!", error);
    }
  };

  // Используем useEffect для загрузки данных при изменении carCode
  useEffect(() => {
    loadCarData();
    loadServicesData(); // Загружаем данные сервисов при монтировании компонента
  }, [loadCarData]); // Добавляем loadCarData как зависимости

  // Обновляем данные в Zustand store
  useEffect(() => {
    if (car) {
      updateCarCode(car.carCode); // Обновляем carCode в store
    }
  }, [car, updateCarCode]);

  useEffect(() => {
    updateTotalPrice(totalPrice); // Обновляем общую стоимость в store
    updateSelectedServices(selectedServices); // Обновляем выбранные услуги в store
  }, [totalPrice, selectedServices, updateTotalPrice, updateSelectedServices]);

  const handleOrderButtonClick = () => {
    if (isBookFormComplete()) {
      handleOpenModal();
    } else {
      handleOpenWarningModal();
      scrollToTop(); // Плавно прокручиваем к верху страницы
    }
  };

  // Если данные еще загружаются, показываем загрузку
  if (!car || !priceList === 0) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.pageContainer}>
      {/* Компонент заголовка страницы с кастомными стилями и переданными пропсами */}
      <Header
        headerSinglePage={styles.customHeaderSinglePage}
        formSinglePage={styles.customFormContainerPage}
        imgSinglePage={whiteCar}
        hideHeading={true} // скрываем заголовок
        hideButton={true} // скрываем кнопку
        onDateChange={setRentalDays} // передаем функцию изменения количества дней аренды
      />
      <div>
        <h2 className={styles.productTitle}>{car.name}</h2>
        <div className={styles.singlePageContainer}>
          {/* Изображение автомобиля */}
          <img
            src={`https://okrentacar.md/admin/cars/uploads/${car.carImage}`}
            alt="auto"
            className={styles.productImg}
          />

          {/* Информация о характеристиках автомобиля */}
          <div className={styles.productInfo}>
            <div>
              <img src={icon1} alt="icon" className={styles.iconSize} />
              <p>An: {car.year}</p>
            </div>
            <div>
              <img src={icon2} alt="icon" className={styles.iconSize} />
              <p>Cutia: {car.transmission} </p>
            </div>
            <div>
              <img src={icon3} alt="icon" className={styles.iconSize} />
              <p>Motor: {car.typeFuel} </p>
            </div>
            <div>
              <img src={icon3} alt="icon" />
              <p>Capacitate motor: {car.capacityMotor}</p>
            </div>
            <div>
              <img src={icon4} alt="icon" className={styles.iconSize} />
              <p>Tip Caroserie: {car.typeBody}</p>
            </div>
            <div>
              <img src={icon5} alt="icon" className={styles.iconSize} />
              <p>Număr uși: {car.numberDoors}</p>
            </div>
            <div>
              <img src={icon6} alt="icon" className={styles.iconSize} />
              <p>Număr pasageri: {car.numberPassenger} </p>
            </div>
          </div>

          <div className={styles.additionalDiv}>
            <img
              src={`https://okrentacar.md/admin/cars/uploads/${car.carImage}`}
              alt="auto"
              className={styles.productImg2}
            />

            {/* Информация о характеристиках автомобиля */}
            <div className={styles.productInfo2}>
              <div className={styles.leftSide}>
                <div>
                  <img src={icon1} alt="icon" />
                  <p>An: {car.year}</p>
                </div>
                <div>
                  <img src={icon2} alt="icon" />
                  <p>Cutia: {car.transmission} </p>
                </div>
                <div>
                  <img src={icon3} alt="icon" />
                  <p>Motor: {car.typeFuel} </p>
                </div>
                <div>
                  <img src={icon3} alt="icon" />
                  <p>Capacitate motor: {car.capacityMotor}</p>
                </div>
              </div>

              <div className={styles.rightSide}>
                <div>
                  <img src={icon4} alt="icon" />
                  <p>Tip Caroserie: {car.typeBody}</p>
                </div>
                <div>
                  <img src={icon5} alt="icon" />
                  <p>Număr uși: {car.numberDoors}</p>
                </div>
                <div>
                  <img src={icon6} alt="icon" />
                  <p>Număr pasageri: {car.numberPassenger} </p>
                </div>
              </div>
            </div>
          </div>
          {/* Дополнительные аксессуары */}
          <div className={styles.additionalOptions2}>
            <h4>Accesorii suplimentare</h4>
            <div>
              <p>Scaune pentru copil, șoferi suplimentari și multe altele.</p>
              <p>
                Dând clic pe „PLASEAZĂ COMANDA”, confirmați faptul că ați citit,
                înțeles și acceptat Termeni generali și Termenii de închiriere
                ai OK RENT A CAR.
              </p>
            </div>
          </div>

          <div className={styles.mobileVersion}>
            <img
              src={`https://okrentacar.md/admin/cars/uploads/${car.carImage}`}
              alt="auto"
              className={styles.productImg3}
            />

            {/* Информация о характеристиках автомобиля */}
            <div className={styles.productInfo3}>
              <div className={styles.leftSide3}>
                <div>
                  <img src={icon1} alt="icon" className={styles.iconSize3} />
                  <p>An: {car.year}</p>
                </div>
                <div>
                  <img src={icon2} alt="icon" className={styles.iconSize3} />
                  <p>Cutia: {car.transmission} </p>
                </div>
                <div>
                  <img src={icon3} alt="icon" className={styles.iconSize3} />
                  <p>Motor: {car.typeFuel} </p>
                </div>
              </div>

              <div className={styles.rightSide3}>
                <div>
                  <img src={icon4} alt="icon" className={styles.iconSize3} />
                  <p>Tip Caroserie: {car.typeBody}</p>
                </div>
                <div>
                  <img src={icon5} alt="icon" className={styles.iconSize3} />
                  <p>Număr uși: {car.numberDoors}</p>
                </div>
                <div>
                  <img src={icon6} alt="icon" className={styles.iconSize3} />
                  <p>Număr pasageri: {car.numberPassenger} </p>
                </div>
              </div>
            </div>
            <div className={styles.lastParagraph}>
              <div>
                <img src={icon3} alt="icon" className={styles.iconSize3} />
                <p>Capacitate motor: {car.capacityMotor}</p>
              </div>
            </div>

            <p className={styles.mobileVersionParagraph}>
              Inchiriați un Mercedes-Benz G500 2017 în Chișinău - Putere și
              Eleganță la Dispoziția Dvs. Spațiu Generos pentru 5 Pasageri: Cu 5
              locuri confortabile, acest SUV de lux este potrivit pentru
              călătorii de afaceri sau de agrement, oferind suficient spațiu și
              confort pentru toți pasagerii. Echipat cu cele mai recente
              tehnologii de siguranță, acest Mercedes-Benz vă oferă liniștea că
              sunteți protejat în timpul călătoriilor.
            </p>

            {/* Дополнительные аксессуары */}
            <div className={styles.additionalOptions3}>
              <h4>Accesorii suplimentare</h4>
              <div>
                <p>Scaune pentru copil, șoferi suplimentari și multe altele.</p>
                <p>
                  Dând clic pe „PLASEAZĂ COMANDA”, confirmați faptul că ați
                  citit, înțeles și acceptat Termeni generali și Termenii de
                  închiriere ai OK RENT A CAR.
                </p>
              </div>
            </div>
          </div>

          <div className={styles.mainContainer}>
            <div className={styles.pricesContainer}>
              {/* Секция с ценами на аренду автомобиля */}
              <div className={styles.priceSection}>
                <h4>Prețul automobilului</h4>
                <div className={styles.pricesParagraphs}>
                  <div>
                    <p>1-2 zile: {priceList.days1to2} €</p>
                    <p>3-7 zile: {priceList.days3to7} €</p>
                    <p>8-15 zile: {priceList.days8to15} €</p>
                  </div>
                  <div>
                    <p>16-21 zile: {priceList.days16to21} €</p>
                    <p>22-29 zile: {priceList.days22to29} €</p>
                    <p>30+ zile: {priceList.days30plus} €</p>
                  </div>
                </div>
              </div>

              {/* Секция с дополнительными услугами */}
              <div className={styles.extraServices}>
                <h4>Servicii suplimentare</h4>
                {services.map((service) => (
                  <div key={service.serviceCode}>
                    <input
                      type="checkbox"
                      id={`service-${service.serviceCode}`}
                      name={`service-${service.serviceCode}`}
                      onChange={() => handleServiceChange(service)} // обработчик изменения услуги
                    />
                    <label htmlFor={`service-${service.serviceCode}`}>
                      {service.nameService}: {service.priceService} €/zi
                    </label>
                  </div>
                ))}
              </div>

              {/* Секция с детализированной информацией по счету */}
              <div className={styles.invoiceDetails}>
                <h4>Detalii factură</h4>
                {/* Статическая часть данных счета */}
                <div>
                  <p>Taxă închiriere auto</p>
                  <strong>{totalCarPrice()} €</strong>
                </div>
                {/* Выбранные услуги и их цены */}
                {selectedServices.map((service, index) => (
                  <div
                    key={service.serviceCode}
                    className={`${styles.preLastChild} ${
                      index === selectedServices.length - 1
                        ? styles.lastService
                        : ""
                    }`}
                  >
                    <p>{service.nameService}</p>
                    <strong>{service.priceService * rentalDays} €</strong>
                  </div>
                ))}
                {/* Последний элемент с итоговой ценой */}
                <div className={styles.lastChildInvoice}>
                  <p>Total</p>
                  <span className={styles.colorStrong}>{totalPrice} €</span>
                </div>
                {/* Кнопка заказа */}
                <OrderButton
                  center={true}
                  onClick={handleOrderButtonClick}
                />{" "}
                {/* Открытие модального окна */}
              </div>

              {/* Модальное окно с предупреждением */}
              <ModalWindow
                isOpen={openWarningModal}
                handleClose={handleCloseWarningModal}
                message="Vă rugăm să completați toate câmpurile din partea de sus."
              />

              {/* Модальное окно с формой заказа */}
              <ModalWindow
                isOpen={openModal}
                handleClose={handleCloseModal}
                shouldScroll={false} // Отключаем прокрутку для этого модального окна
              />
            </div>
          </div>

          {/* Дополнительная информация о преимуществах аренды */}
          <div className={styles.detailsContainer}>
            <div className={styles.excellentChoice}>
              <h4>O decizie inspirată!</h4>
              <div className={styles.excellentChoiceList}>
                <div>
                  <img src={v} alt="icon" />
                  <p>Evaluarea clienților: 9,0 / 10</p>
                </div>
                <div>
                  <img src={v} alt="icon" />
                  <p>Ghișeu ușor accesibil</p>
                </div>
                <div>
                  <img src={v} alt="icon" />
                  <p>Cea mai populară politică de combustibil</p>
                </div>
                <div>
                  <img src={v} alt="icon" />
                  <p>Personal prietenos</p>
                </div>
                <div>
                  <img src={v} alt="icon" />
                  <p>Timp redus de așteptare</p>
                </div>
                <div>
                  <img src={v} alt="icon" />
                  <p>Mașini întreținute impecabil</p>
                </div>
              </div>

              {/* Включенные в стоимость опции */}
              <div className={styles.mileOption}>
                <h4>Inclus în preț</h4>
                <div>
                  <img src={v} alt="icon" />
                  <p>Mile/Kilometri nelimitați</p>
                </div>
              </div>

              {/* Дополнительные аксессуары */}
              <div className={styles.additionalOptions}>
                <h4>Accesorii suplimentare</h4>
                <div>
                  <p>
                    Scaune pentru copil, șoferi suplimentari și multe altele.
                  </p>
                  <p>
                    Dând clic pe „PLASEAZĂ COMANDA”, confirmați faptul că ați
                    citit, înțeles și acceptat Termeni generali și Termenii de
                    închiriere ai OK RENT A CAR.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
