import React, { useState } from 'react';
import styles from './SortAutosPage.module.css';

export default function SortAutosPage({ setSortOrder }) {
  const [activeRecommendation, setActiveRecommendation] = useState("Recomandat");
  
  // Массив рекомендаций, которые отображаются на странице
  const recommendations = ["Recomandat", "Preț minim", "Preț maxim"];

  const handleRecommendationClick = (recommendation) => {
    setActiveRecommendation(recommendation);
    setSortOrder(recommendation); // Устанавливаем порядок сортировки
  };

  return (
    <div className={styles.recommendations}>
      {recommendations.map((recommendation) => (
        <div
          key={recommendation}
          className={activeRecommendation === recommendation ? styles.active : ""}
          onClick={() => handleRecommendationClick(recommendation)}
        >
          {recommendation}
        </div>
      ))}
    </div>
  );
}
