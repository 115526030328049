import { create } from 'zustand';

const useStore = create((set) => ({
  rentalDetails: {
    start: { date: null, time: null },
    end: { date: null, time: null },
    rentalDays: 1,
    pickupLocation: '',
    dropoffLocation: '',
    carCode: '', // Поле carCode
    totalPrice: 0, // Поле для хранения totalPrice
    selectedServices: [], // Массив для выбранных serviceCode
  },

  // Функция для обновления общих деталей аренды
  updateRentalDetails: (details) => set((state) => ({
    rentalDetails: {
      ...state.rentalDetails,
      ...details,
    },
  })),

  // Функция для обновления carCode
  updateCarCode: (carCode) => set((state) => ({
    rentalDetails: {
      ...state.rentalDetails,
      carCode,
    },
  })),

  // Функция для обновления totalPrice
  updateTotalPrice: (totalPrice) => set((state) => ({
    rentalDetails: {
      ...state.rentalDetails,
      totalPrice,
    },
  })),

  // Функция для обновления выбранных сервисов
  updateSelectedServices: (services) => set((state) => ({
    rentalDetails: {
      ...state.rentalDetails,
      selectedServices: services, // services - это массив serviceCode выбранных услуг
    },
  })),

  // Проверка заполненности полей для BookForm
  isBookFormComplete: () => {
    const { start, end, pickupLocation, dropoffLocation } = useStore.getState().rentalDetails;
    return (
      start.date && 
      start.time && 
      end.date && 
      end.time && 
      pickupLocation && 
      dropoffLocation
    );
  },
}));

export default useStore;
