import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import NavMenu from "./components/NavMenu/NavMenu.jsx";
import MainPage from "./pages/MainPage/MainPage.jsx";
import AutosPage from "./pages/AutosPage/AutosPage.jsx";
import PricesPage from "./pages/PricesPage/PricesPage.jsx";
import AboutUsPage from "./pages/AboutUsPage/AboutUsPage.jsx";
import ContactPage from "./pages/ContactPage/ContactPage.jsx";
import SingleProductPage from "./pages/SingleProductPage/SingleProductPage.jsx";
import Footer from "./components/Footer/Footer.jsx";

function App() {
  const location = useLocation(); // Хук для получения текущего маршрута

  // Прокрутка страницы вверх при изменении маршрута
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <NavMenu />
      <main>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/autos" element={<AutosPage />} />
          <Route path="/prices" element={<PricesPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/product/:carCode" element={<SingleProductPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
