import React, { useState, useEffect } from "react";
import styles from "./BookForm.module.css";
import searchIcon from "../../media/icons/searchIcon.svg";
import { useForm } from "@formspree/react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ro } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import CustomTimePicker from "../CustomTimePicker/CustomTimePicker";
import useStore from "../../store";
import { useNavigate, useLocation } from "react-router-dom"; // Импорт для навигации и определения текущего пути

registerLocale("ro", ro);

export default function BookForm({
  formSinglePage,
  hideHeading,
  hideButton,
  formAutosPage,
  formPricesPage,
  onDateChange = () => {},
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSubmit, submitting } = useForm("mvgpnkgn");

  const { rentalDetails, updateRentalDetails } = useStore();

  const today = new Date(); // сегодняшняя дата
  // Функция для вычисления следующего дня после выбранной даты
  const getNextDay = (date) => {
    const nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  };

  const [rentalInfo, setRentalInfo] = useState({
    start: {
      date: rentalDetails.start.date || null,
      time: rentalDetails.start.time || null,
    },
    end: {
      date: rentalDetails.end.date || null,
      time: rentalDetails.end.time || null,
    },
    rentalDays: rentalDetails.rentalDays || 1,
    pickupLocation: rentalDetails.pickupLocation || "",
    dropoffLocation: rentalDetails.dropoffLocation || "",
  });

  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);

  const calculateRentalDays = () => {
    const { start, end } = rentalInfo;

    if (!start.date || !end.date) return 1;

    if (!start.time || !end.time) {
      const startDateOnly = new Date(start.date.toISOString().split("T")[0]);
      const endDateOnly = new Date(end.date.toISOString().split("T")[0]);

      const diffMs = endDateOnly - startDateOnly;

      if (diffMs < 0) return 0;

      return Math.ceil(diffMs / (1000 * 60 * 60 * 24));
    }

    const startDateTime = new Date(
      `${start.date.toISOString().split("T")[0]}T${start.time}`
    );
    const endDateTime = new Date(
      `${end.date.toISOString().split("T")[0]}T${end.time}`
    );

    const diffMs = endDateTime - startDateTime;

    if (diffMs <= 0) return 0;

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const remainingHours = (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);

    return remainingHours > 0 ? diffDays + 1 : diffDays;
  };

  const updateRentalDays = () => {
    const rentalDays = calculateRentalDays();
    setRentalInfo((prev) => ({
      ...prev,
      rentalDays,
    }));

    updateRentalDetails({
      ...rentalInfo,
      rentalDays,
      start: { ...rentalInfo.start },
      end: { ...rentalInfo.end },
    });
    onDateChange(rentalDays);
  };

  useEffect(() => {
    updateRentalDays();
  }, [
    rentalInfo.start,
    rentalInfo.end,
    rentalInfo.pickupLocation,
    rentalInfo.dropoffLocation,
  ]);

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setRentalInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (type, date) => {
    if (type === "start") {
      setRentalInfo((prev) => ({
        ...prev,
        start: { ...prev.start, date },
        // Автоматически обновляем дату "сдачи", если она раньше или равна "дате получения"
        end: {
          ...prev.end,
          date:
            prev.end.date && new Date(prev.end.date) <= new Date(date)
              ? getNextDay(date)
              : prev.end.date,
        },
      }));
      setShowStartDate(false); // Закрыть календарь после выбора даты
      setShowEndDate(false); // Закрываем другой календарь
    } else if (type === "end") {
      setRentalInfo((prev) => ({
        ...prev,
        end: { ...prev.end, date },
      }));
      setShowStartDate(false); // Закрываем другой календарь
      setShowEndDate(false); // Закрыть календарь после выбора даты
    }
  };

  const handleToggleCalendar = (calendarType) => {
    if (calendarType === "start") {
      setShowStartDate(!showStartDate);
      setShowEndDate(false);
    } else if (calendarType === "end") {
      setShowEndDate(!showEndDate);
      setShowStartDate(false);
    }
  };

  const handleTimeChange = (type, time) => {
    setRentalInfo((prev) => ({
      ...prev,
      [type]: { ...prev[type], time },
    }));
  };

  const handleSubmitForm = (event) => {
    event.preventDefault();

    const startDateTime = new Date(
      `${rentalInfo.start.date}T${rentalInfo.start.time}`
    );
    const endDateTime = new Date(
      `${rentalInfo.end.date}T${rentalInfo.end.time}`
    );

    const rentalDetails = {
      startDate: startDateTime,
      endDate: endDateTime,
      rentalDays: rentalInfo.rentalDays,
      pickupLocation: rentalInfo.pickupLocation,
      dropoffLocation: rentalInfo.dropoffLocation,
    };
    // Проверяем, если текущая страница - AutosPage
    if (location.pathname === "/autos") {
      // Находим элемент для прокрутки
      const mainContainer = document.querySelector(`.${styles.mainContainer}`);
      if (mainContainer) {
        // Прокручиваем ниже, чтобы BookForm пропал и был виден раздел сортировки
        const offset = -170; // смещение в пикселях для точного позиционирования
        window.scrollTo({
          top: mainContainer.offsetTop - offset,
          behavior: "smooth",
        });
      }
    } else if (location.pathname === "/prices") {
      // Находим элемент для прокрутки
      const mainContainer = document.querySelector(`.${styles.mainContainer}`);
      if (mainContainer) {
        // Прокручиваем ниже, чтобы BookForm пропал и был виден раздел сортировки
        const offset = -160; // смещение в пикселях для точного позиционирования
        window.scrollTo({
          top: mainContainer.offsetTop - offset,
          behavior: "smooth",
        });
      }
    } else {
      // Перенаправляем на AutosPage и прокручиваем
      navigate("/autos");
      setTimeout(() => {
        const mainContainer = document.querySelector(
          `.${styles.mainContainer}`
        );
        if (mainContainer) {
          const offset = -170; // смещение в пикселях для точного позиционирования
          window.scrollTo({
            top: mainContainer.offsetTop - offset,
            behavior: "smooth",
          });
        }
      }, 200); // Задержка для завершения навигации
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(`.${styles.dateWrapper}`)) return; // Если клик внутри календаря, игнорируем
      setShowStartDate(false); // Закрыть календарь
      setShowEndDate(false); // Закрыть календарь
    };

    document.addEventListener("mousedown", handleClickOutside); // Добавляем слушатель события клика

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Убираем слушатель при размонтировании
    };
  }, []);

  const handleTouchStart = (e) => {
    e.preventDefault(); // Предотвратить увеличение
  };

  useEffect(() => {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isIOS) {
      document.querySelectorAll("select option[disabled]").forEach((option) => {
        option.style.display = "none"; // Hide disabled options
      });
    }
  }, []);

  return (
    <div
      className={`${styles.defaultForm} ${formSinglePage} ${formAutosPage} ${formPricesPage}`}
    >
      {!hideHeading && (
        <h2 className={styles.formTitle}>
          Călătorii confortabile, prețuri accesibile
        </h2>
      )}

      <form className={styles.form} onSubmit={handleSubmitForm}>
        <div className={styles.formGrid}>
          <div className={styles.inputRow}>
            {/* Выбор места забирания */}
            <div className={styles.selectWrapper}>
              <img
                className={styles.searchIcon}
                src={searchIcon}
                alt="search-icon"
              />
              <select
                className={styles.locationSelector}
                name="pickupLocation"
                value={rentalInfo.pickupLocation}
                onChange={handleLocationChange}
                required
              >
                <option value="" disabled hidden>
                  Locația de preluare
                </option>
                <option value="chisinau-aeroport">Chișinău aeroport</option>
                <option value="chisinau-centru">Chișinău centru</option>
              </select>
            </div>

            <div className={styles.dateWrapper}>
              <DatePicker
                locale="ro"
                dateFormat="dd/MM/yyyy"
                selected={rentalInfo.start.date}
                showPopperArrow={false}
                onChange={(date) => handleDateChange("start", date)}
                minDate={today}
                selectsStart
                className={styles.dateSelector}
                placeholderText="Data de preluare"
                popperPlacement="bottom-start"
                open={showStartDate}
                onInputClick={() => handleToggleCalendar("start")}
                onTouchStart={handleTouchStart}
                readOnly
                required
              />
            </div>

            <CustomTimePicker
              value={rentalInfo.start.time}
              onChange={(time) => handleTimeChange("start", time)}
              required
            />
          </div>

          <div className={styles.inputRow}>
            <div className={styles.selectWrapper}>
              <img
                className={styles.searchIcon}
                src={searchIcon}
                alt="search-icon"
              />
              <select
                className={styles.locationSelector}
                name="dropoffLocation"
                value={rentalInfo.dropoffLocation}
                onChange={handleLocationChange}
                required
              >
                <option value="" disabled hidden>
                  Locația de predare
                </option>
                <option value="chișinău-aeroport">Chișinău aeroport</option>
                <option value="chișinău-centru">Chișinău centru</option>
              </select>
            </div>

            <div className={styles.dateWrapper}>
              <DatePicker
                locale="ro"
                dateFormat="dd/MM/yyyy"
                selected={rentalInfo.end.date}
                showPopperArrow={false}
                onChange={(date) => handleDateChange("end", date)}
                minDate={
                  rentalInfo.start.date
                    ? getNextDay(rentalInfo.start.date)
                    : today
                }
                selectsEnd
                className={styles.dateSelector}
                placeholderText="Data de predare"
                popperPlacement="bottom-start"
                open={showEndDate}
                onInputClick={() => handleToggleCalendar("end")}
                onTouchStart={handleTouchStart}
                readOnly
                required
              />
            </div>

            <CustomTimePicker
              value={rentalInfo.end.time}
              onChange={(time) => handleTimeChange("end", time)}
              required
            />
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          {!hideButton && (
            <button
              className={styles.formButton}
              type="submit"
              disabled={submitting}
            >
              Căutare
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
