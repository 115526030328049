import React from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import styles from "./ModalWindow.module.css"; // Импортируем ваш CSS модуль
import OrderForm from "../OrderForm/OrderForm";

export default function ModalWindow({ isOpen, handleClose, message }) {
  // Функция закрытия и возврата к началу страницы
  const handleCloseAndScrollTop = () => {
    handleClose();
    window.scrollTo({ top: 0, behavior: "smooth" }); // Возврат на верх страницы
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseAndScrollTop}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div
          className={`${styles.modalContent} ${
            message ? styles.messageModal : styles.formModal
          }`}
        >
          {message ? (
            <div className={styles.alert}>
              <p>{message}</p>
              <button
                className={styles.okButton}
                onClick={handleCloseAndScrollTop}
              >
                OK {/* Кнопка для подтверждения */}
              </button>
            </div>
          ) : (
            <OrderForm handleClose={handleClose} />
          )}
        </div>
      </Fade>
    </Modal>
  );
}
