import React, { useEffect, useState } from "react";
import styles from "./ProductsContainerWithPrices.module.css";
import ProductCardWithPrices from "../ProductCardWithPrices/ProductCardWithPrices";
import { fetchCars } from "../../api/api"; // Импортируем API для получения данных
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

export default function ProductsContainerWithPrices({
  buttonMargin,
  filters = [],
}) {
  const [cars, setCars] = useState([]); // Состояние для хранения данных о машинах
  const [visibleCars, setVisibleCars] = useState(5); // Начальное количество отображаемых машин

  const [isLoading, setIsLoading] = useState(true);
   // Загружаем данные о машинах при монтировании компонента
   useEffect(() => {
    const loadCars = async () => {
      try {
        setIsLoading(true); // Устанавливаем состояние загрузки
        const data = await fetchCars();
        setCars(data); // Сохраняем данные в состояние
      } catch (error) {
        console.error("Error fetching cars data:", error);
      } finally {
        setIsLoading(false); // Выключаем индикатор загрузки после завершения загрузки данных
      }
    };

    loadCars();
  }, []);

  // Функция для фильтрации автомобилей по выбранным типам кузова
  const getFilteredCars = () => {
    if (!filters || filters.typeBody.length === 0) return cars; // Если фильтры отсутствуют, возвращаем все машины

    return cars.filter((car) => {
      // Если выбран фильтр "Toate Tipurile", отображаем все машины
      if (filters.typeBody.includes("Toate Tipurile")) {
        return true;
      }

      // Фильтруем машины по полю typeBody
      return filters.typeBody.includes(car.typeBody);
    });
  };

  // Функция для загрузки большего количества машин
  const loadMoreCars = () => {
    setVisibleCars((prevVisibleCars) =>
      Math.min(prevVisibleCars + 2, cars.length)
    );
  };

  return (
    <div>
      {isLoading ? (
        <LoadingIndicator /> // Показываем индикатор загрузки, пока данные загружаются
      ) : (
      <div className={styles.productsContainer}>
        {/* Проверяем, есть ли доступные машины после фильтрации */}
        {Array.isArray(cars) && getFilteredCars().length === 0 ? (
            <p className={styles.noAutosParagraph}>
              Ne pare rău, nu sunt disponibile astfel de automobile la moment.
            </p>
          ) : (
            getFilteredCars()
              .slice(0, visibleCars)
              .map((car) => (
                <ProductCardWithPrices key={car.carCode} car={car} />
              ))
          )}
        </div>
      )}

      {/* Кнопка для загрузки большего количества машин */}
      {!isLoading && visibleCars < getFilteredCars().length && (
        <button
          className={styles.moreAutosButton}
          onClick={loadMoreCars}
          style={{ marginBottom: buttonMargin }}
        >
          Mai mult
        </button>
      )}
    </div>
  );
}