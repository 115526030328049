import React, { useState, useRef } from "react"; // Импортируйте useState
import styles from "./ContactForm.module.css";
import { useForm } from '@formspree/react';
import ModalWindow from "../ModalWindow/ModalWindow";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    nume: "",
    email: "",
    telefon: "", // Изначально оставляем пустым
    mesaj: "",
  });
  

  const [errors, setErrors] = useState({}); // Состояние для хранения ошибок

  const formRef = useRef(null);

  const [state, handleSubmitFormspree] = useForm("mjkvrrng");

  const [isModalOpen, setModalOpen] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    handleSubmitFormspree(event).then(() => {
      setModalOpen(true); // Открываем модальное окно при успешной отправке
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const triggerSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit(); // Программно отправляем форму
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Логика для полей "nume" — разрешаем ввод только букв
    if (name === "nume") {
      const regex = /^[a-zA-Zа-яА-ЯёЁ\s]*$/; // Разрешаем только буквы и пробелы
      if (regex.test(value) || value === "") {
        setFormData({ ...formData, [name]: value });
      }
    } else if (name === "telefon") {
      // Логика для телефона — разрешаем ввод только цифр
      const regex = /^[0-9]*$/;
      if (regex.test(value)) {
        // Если ввели значение и это телефон
        if (value.length === 0) {
          setFormData({ ...formData, [name]: "" }); // Если поле пустое, оставляем его пустым
        } else if (value.length < 10) {
          setFormData({ ...formData, [name]: `0${value.replace(/^0/, "")}` }); // Убедимся, что номер начинается с 0
        }
      }
    } else {
      setFormData({ ...formData, [name]: value }); // Для остальных полей просто обновляем
    }
  };

  const handleFocus = (event) => {
    // Устанавливаем фокус на телефон
    if (event.target.name === "telefon") {
      if (formData.telefon === "") {
        setFormData({ ...formData, telefon: "0" }); // Устанавливаем значение на 0 при фокусе
      }
    }
  };

  const handleBlur = (event) => {
    // Если поле телефона пустое, устанавливаем его на "Telefon"
    if (event.target.name === "telefon" && formData.telefon === "0") {
      setFormData({ ...formData, telefon: "" }); // Сбрасываем на пустое значение
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    // Проверяем каждое поле на корректность
    if (!formData.nume) {
      newErrors.nume = "Numele este obligatoriu.";
    }

    if (!formData.email) {
      newErrors.email = "Emailul este obligatoriu.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Emailul este invalid.";
    }

    if (formData.telefon.length !== 9) {
      newErrors.telefon = "Telefonul trebuie să conțină exact 9 cifre.";
    }

    if (!formData.mesaj) {
      newErrors.mesaj = "Mesajul este obligatoriu.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Возвращаем true, если ошибок нет

    // Проверка на успешную отправку в Formspree
    if (state.succeeded) {
      return <p>Спасибо! Ваша заявка отправлена.</p>;
    }
  };

  

  return (
    <div className={styles.mainFormContainer}>
      <form className={styles.contactForm} ref={formRef} onSubmit={handleSubmit}>
        <div className={styles.contactFormContainer}>
          <div className={styles.leftSideInputs}>
            <input 
              type="text" 
              name="nume"
              placeholder="Nume/Prenume" 
              value={formData.nume}
              onChange={handleChange}
              required
            />
            {errors.nume && <span className={styles.error}>{errors.nume}</span>} {/* Ошибка имени */}

            <input 
              type="email" 
              placeholder="Email" 
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className={styles.error}>{errors.email}</span>} {/* Ошибка email */}

            <input 
              type="tel" 
              placeholder={formData.telefon === "" ? "Telefon" : "0"} // Отображаем "Telefon" если поле пустое
              name="telefon"
              value={formData.telefon === "0" ? "" : formData.telefon} // Если 0, то отображаем пустое значение
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              required 
            />
            {errors.telefon && <span className={styles.error}>{errors.telefon}</span>} {/* Ошибка телефона */}
          </div>
          <div className={styles.rightSideTextArea}>
            <textarea 
              name="mesaj" 
              placeholder="Mesajul vostru" 
              value={formData.mesaj}
              onChange={handleChange}
              required 
            ></textarea>
            {errors.mesaj && <span className={styles.error}>{errors.mesaj}</span>} {/* Ошибка сообщения */}
          </div>
        </div>
        
      </form>
      <div className={styles.formButton}>
          <button onClick={triggerSubmit} >Expediază</button> {/* Кнопка отправки */}
        </div>
        <ModalWindow 
        isOpen={isModalOpen} 
        handleClose={handleCloseModal} 
        message="Vă mulțumim! Mesajul dumneavoastră a fost trimis cu succes." 
      />
    </div>
  );
}
