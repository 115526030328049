import React from 'react'
import Header from '../../components/Header/Header';
import ProductsContainer from '../../components/ProductsContainer/ProductsContainer';
import Benefits from '../../components/Benefits/Benefits';


export default function MainPage() {
  return (
    <div>
      <Header/>
      <ProductsContainer />
      <Benefits />
    </div>
  )
}
